import React, { Component } from 'react';
import ImageInfo from '../../../models/ImageInfo';
import blackCaretImage from '../../../web-common/assets/caret.svg';
import whiteCaretImage from '../../../web-common/assets/caret-white.svg';
import './styles.scss';

export default class ImageCarousel extends Component<{ images: ImageInfo[] }> {

  state = {
    imageIndex: 0
  };

  render() {

    const caretImage = this._getImage().isDark ? whiteCaretImage : blackCaretImage;
    return (
      <div className="image-carousel">
        <div className="arrow">
          <img src={caretImage} onClick={this._goBack} role="button" alt="left-arrow"/>
        </div>
        {this._renderImageOrVideo()}
        <div className="arrow">
          <img src={caretImage} onClick={this._goForward} role="button" alt="left-arrow" />
        </div>
      </div>
    );
  }

  private _getImage() {

    return this.props.images[this.state.imageIndex];
  }

  private _goForward = () => {

    const { imageIndex } = this.state;
    this.setState({ imageIndex: imageIndex + 1 < this.props.images.length ? imageIndex + 1 : 0 });
  }

  private _goBack = () => {

    const { imageIndex } = this.state;
    this.setState({ imageIndex: imageIndex - 1 >= 0 ? imageIndex - 1 : this.props.images.length - 1 });
  }

  private _renderImageOrVideo() {

    const image = this._getImage();
    const isVideo = image.url.indexOf('.mp4') !== -1;
    if (isVideo) {
      return <video style={image.style} src={image.url} autoPlay={true} muted={true} loop={true} playsInline={true}/>
    }
    return <img style={image.style} className="focused-image" src={image.url} alt={image.alt}/>;
  }
}
