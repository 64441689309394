import React, { Component } from 'react';
import Decimal from 'decimal.js';
import ProductId from '../../web-common/models/products/ProductId';
import parseQueryString from '../../web-common/utils/parseQueryString';
import '../../web-common/styles/link-button.scss';
import calculateOrderTotal from '../../web-common/models/orders/calculateOrderTotal';
import Coupon from '../../web-common/models/orders/Coupon';
import StorePage from '../../components/StorePage';
import './styles.scss';
import Cart from '../../services/Cart';
import CartItem from '../../models/CartItem';
import CartTable from '../../components/CartTable';
import AddCouponModal from './AddCouponModal';
import arrowImage from './assets/arrow.svg';
import '../../web-common/styles/button-reset.scss';
import StoreService from '../../services/StoreService';

interface CartPageState {
  products: CartItem[];
  isMounted: boolean;
  showCouponModal: boolean;
  coupon?: Coupon;
}

export default class CartPage extends Component<{}, CartPageState> {

  state = {
    products: Cart.getItems(true),
    isMounted: false,
    showCouponModal: false,
    coupon: undefined as Coupon|undefined
  }

  async componentDidMount() {

    this.setState({ isMounted: true });

    this._processQueryStringParams();

    const couponCode = Cart.getCouponCode();
    if (couponCode) {
      try {
        const coupon = await StoreService.getCoupon(couponCode);
        this.setState({ coupon });
      } catch (error) {
        console.error('An error occurred while fetching the coupon', error);
        Cart.clearCouponCode();
      }
    }
  }

  render() {

    const totalInfo = calculateOrderTotal(Decimal, this.state.products, this.state.coupon);

    return (
      <StorePage className="cart-page">
        <h1>Cart</h1>
        <h2>Create a bundle to <span>save up to 20%</span></h2>
        <CartTable
          totalInfo={totalInfo}
          editableWithQuantityChangedHandler={this._handleQuantityChanged}/>
        {this._renderCoupon()}
        <div className="bottom-section">
          {this._renderAddCouponButton()}
          <a className="checkout-link"
            href={totalInfo.total !== '0.00' && this.state.isMounted ? '/checkout' : undefined}>
              Checkout
              <img src={arrowImage} alt="arrow"/>
          </a>
        </div>
        {this.state.showCouponModal && (
          <AddCouponModal
            onCloseRequested={this._handleCloseCouponModal}
            onCouponAdded={this._handleCouponAdded}/>)}
      </StorePage>
    );
  }

  private _handleAddCouponClicked = () => this.setState({ showCouponModal: !this.state.showCouponModal });

  private _handleCloseCouponModal = () => this.setState({ showCouponModal: false });

  private _handleCouponAdded = (coupon: Coupon) => {

    Cart.setCouponCode(coupon.code);
    this.setState({
      coupon,
      showCouponModal: false
    });
  };

  private _handleQuantityChanged = (productId: ProductId, quantity: number) => {

    Cart.setQuantity(productId, quantity);
    this.setState({
      products: Cart.getItems(true)
    });
  };

  private _handleRemoveCouponClicked = () => {

    this.setState({ coupon: undefined });
    Cart.clearCouponCode();
  };

  private _processQueryStringParams() {

    const { products, coupon } = parseQueryString(location.search);
    // Links in the Asset Store pages use a `product` query parameter
    // to specify products that should be in the bundle.
    if (products) {
      const productIds = products.split('-');
      for (const id of productIds) {
        const productId = id as ProductId;
        if (Cart.getQuantity(productId as ProductId) === 0) {
          Cart.setQuantity(productId, 1);
        }
      }
      this.setState({ products: Cart.getItems(true) });
    }

    if (coupon) {
      Cart.setCouponCode(coupon);
    }

    if (products || coupon) {
      history.replaceState(null, '', location.pathname);
    }
  }

  private _renderAddCouponButton() {

    if (this.state.coupon) {
      // The "Add coupon code" button is only shown when there
      // is no coupon, so return a placeholder instead when there is one.
      return <div/>;
    }
    return (
      <button
        className="link-button discount"
        onClick={this._handleAddCouponClicked}>
        Add a coupon code
      </button>
    );
  }

  private _renderCoupon() {

    const { coupon } = this.state;
    if (!coupon) {
      return;
    }
    return (
      <div className="coupon">
        <div>
          <div>
            <b>Coupon code:</b> {coupon.code} <span>({coupon.discountPercentage}% off)</span>
          </div>
          <button onClick={this._handleRemoveCouponClicked} title="Remove coupon" className="button-reset">×</button>
        </div>
      </div>
    );
  }
}
