import React, { Component } from 'react';
import WebViewProductDescription from '../../web-common/components/WebViewProductDescription';
import StoreLinks from '../../web-common/components/StoreLinks';
import Seo from '../Seo';
import StorePage from '../StorePage';
import ProductPageInfo from '../../models/ProductPageInfo';
import Cart from '../../services/Cart';
import shieldImage from './assets/shield.svg';
import CartButton from './CartButton';
import ImageCarousel from './ImageCarousel';
import payPalImage from './assets/paypal.svg';
import stripeImage from './assets/stripe.svg';
import './styles.scss';
import ProductId from '../../web-common/models/products/ProductId';
import ProductUrlPaths from '../../models/ProductUrlPaths';

export default class ProductPage extends Component<{ pageContext: { productPageInfo: ProductPageInfo }}> {

  state = {
    inCart: !!Cart.getQuantity(this._productId)
  };

  render() {

    const { productPageInfo } = this.props.pageContext;
    const { product } = productPageInfo;
    return (
      <StorePage className="product-page">
        <Seo title={product.name}/>
        <h1>{product.name}</h1>
        {productPageInfo.urlPath === ProductUrlPaths.Overview && <StoreLinks/>}
        {this._renderReviewsSummary()}
        <section className="top-section">
          <div className="carousel-container">
            <ImageCarousel images={productPageInfo.images}/>
          </div>
          <div className="purchase-info">
            <div className="price">{productPageInfo.priceOverride || `$${product.price}`}</div>
            <a className="bundle"
               href="/cart"
               onClick={this._handleBuyNowOrBundleClicked}>
                 <b>Save up to 20%</b> <span>with a bundle</span>
            </a>
            <div className="buy-buttons">
              <a className="buy-now" onClick={this._handleBuyNowOrBundleClicked} href="/cart">Buy Now</a>
              {this._productId && <CartButton onClick={this._handleCartButtonClicked} inCart={this.state.inCart}/>}
            </div>
            <div className="refund-policy">
              <img src={shieldImage} alt="shield"/>
              Refund policy
            </div>
            <div className="secure-checkout">
              <div>
                Secure checkout powered by:
              </div>
              <div className="checkout-badges">
                <div className="paypal">
                  <img src={payPalImage} alt="PayPal"/>
                </div>
                <div className="stripe">
                  <img src={stripeImage} alt="Stripe" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <WebViewProductDescription {...productPageInfo.descriptionProps}/>
      </StorePage>
    );
  }

  private get _productId() {

    return this.props.pageContext.productPageInfo.product.id;
  }

  private _handleBuyNowOrBundleClicked = () => {

    // Fallback to the standalone package on the overview page.
    const productId = this._productId || ProductId.UnityWebViewStandalone;
    const isInCart = Cart.getQuantity(productId) > 0;
    if (!isInCart) {
      Cart.setQuantity(productId, 1);
    }
  };

  private _handleCartButtonClicked = () => {

    const isInCart = Cart.getQuantity(this._productId) > 0;
    if (isInCart) {
      Cart.setQuantity(this._productId, 0);
    } else {
      Cart.setQuantity(this._productId, 1);
    }
    this.setState({ inCart: !isInCart });
  };

  private _renderReviewsSummary() {

    const { productPageInfo } = this.props.pageContext;
    if (!productPageInfo.numberOfReviews) {
      return;
    }
    return (
      <div className="review-summary">
        <span>
          <span>★</span>
          <span>★</span>
          <span>★</span>
          <span>★</span>
          <span>★</span>
        </span>
        <span>
          <a target="_blank" href={productPageInfo.reviewsUrl || `${productPageInfo.assetStoreUrl}#reviews`}>{productPageInfo.numberOfReviews} Reviews</a>
        </span>
      </div>
    )
  }
}
