import React, { Component } from 'react';
import Decimal from 'decimal.js';
import { CardElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import StripeCardForm from './StripeCardForm';
import calculateOrderTotal, { OrderTotalInfo } from '../../web-common/models/orders/calculateOrderTotal';
import Coupon from '../../web-common/models/orders/Coupon';
import StorePage from '../../components/StorePage';
import './styles.scss';
import VuplexApi from '../../web-common/client/VuplexApi';
import PublicApiError from '../../web-common/models/errors/PublicApiError';
import ExternalUser from '../../web-common/models/accounts/ExternalUser';
import OrderRequest from '../../web-common/models/orders/OrderRequest';
import OrderCreationResponse from '../../web-common/models/orders/OrderCreationResponse';
import PaymentMethod from '../../web-common/models/orders/PaymentMethod';
import Urls from '../../web-common/config/Urls';
import Spinner from '../../web-common/components/Spinner';
import PageError, { PageErrorProps } from '../../web-common/components/PageError';
import LoginRedirectError from '../../web-common/client/LoginRedirectError';
import Cart from '../../services/Cart';
import StoreService from '../../services/StoreService';
import CartTable from '../../components/CartTable';
import Config from '../../config';
import stripeImage from './assets/stripe-dark.svg';
import '../../web-common/styles/button-reset.scss';

interface CheckoutPageState {
  user: ExternalUser|null;
  errorProps: PageErrorProps|null;
  totalInfo: OrderTotalInfo;
  cardInfoValid: boolean;
  paymentInProgress: PaymentMethod|null;
  paymentComplete: PaymentMethod|null;
  loadingCoupon: boolean;
  coupon?: Coupon;
}

export default class CheckoutPage extends Component<{}, CheckoutPageState> {

  state = {
    user: null as ExternalUser|null,
    errorProps: null as PageErrorProps|null,
    totalInfo: calculateOrderTotal(Decimal, Cart.getItems()),
    cardInfoValid: false,
    paymentInProgress: null as PaymentMethod|null,
    paymentComplete: null as PaymentMethod|null,
    loadingCoupon: true,
    coupon: undefined as Coupon|undefined
  };

  componentDidMount() {
    if (Cart.isEmpty()) {
      location.href = '/cart';
      return;
    }
    this._loadUser();
    this._loadCoupon();
  }

  render() {
    return (
      <StorePage className="checkout-page">
        {this._renderView()}
      </StorePage>
    );
  }

  private _stripe?: Stripe;
  private _stripeElements?: StripeElements;

  private async _createOrder(paymentMethod: PaymentMethod): Promise<OrderCreationResponse> {

    const orderRequest: OrderRequest = {
      paymentMethod,
      items: this.state.totalInfo.items.map(i => ({ productId: i.productId, quantity: i.quantity })),
      couponCode: this.state.coupon?.code
    };
    return StoreService.createOrder(orderRequest);
  }

  private _createPaypalOrder = async () => {

    const order = await this._createOrder(PaymentMethod.PayPal);
    return order.paymentProviderClientOrderId;
  };

  private _handlePaymentComplete(paymentMethod: PaymentMethod) {

    this.setState({
      paymentInProgress: null,
      paymentComplete: paymentMethod
    });
    Cart.clear();
  }

  private _handlePayPalError = (error) => {

    this.setState({
      errorProps: {
        title: 'Sorry, but a PayPal error occurred',
        error: error.toString()
      }
    });
  }

  private _handlePayPalPaymentApproved = async (data, actions) => {
    try {
      // Show a spinner while capturing the PayPal payment.
      // Note that the PayPal element must remain in the page during this.
      this.setState({ paymentInProgress: PaymentMethod.PayPal });
      await actions.order.capture();
      this._handlePaymentComplete(PaymentMethod.PayPal);
    } catch (error) {
      this.setState({
        errorProps: {
          title: 'Sorry, but a PayPal error occurred',
          error: error.message
        },
        paymentInProgress: null
      });
    }
  };

  private _handleStripeInfoChange = (cardInfoValid: boolean) => {

    this.setState({ cardInfoValid });
  };

  private _handleStripeReady = (stripe: Stripe, stripeElements: StripeElements) => {

    this._stripe = stripe;
    this._stripeElements = stripeElements;
  }

  private _handleStripeSubmitClicked = async () => {

    this.setState({ paymentInProgress: PaymentMethod.Stripe });
    try {
      const { paymentProviderClientOrderId } = await this._createOrder(PaymentMethod.Stripe);
      const cardElement = this._stripeElements!.getElement(CardElement);
      const { error, paymentIntent } = await this._stripe!.confirmCardPayment(
        paymentProviderClientOrderId,
        {
          payment_method: {
            card: cardElement!,
            billing_details: {
              email: this.state.user!.email
            }
          },
        }
      );
      if (error) {
        this.setState({
          paymentInProgress: null,
          errorProps: {
            title: 'Sorry, but your card payment failed',
            error: error.message!
          }
        });
      } else {
        if (paymentIntent!.status === 'succeeded') {
          this._handlePaymentComplete(PaymentMethod.Stripe);
        } else {
          this.setState({
            paymentInProgress: null,
            errorProps: { error: `The payment failed with status "${paymentIntent!.status}"` }
          })
        }
      }
    } catch (error) {
      this.setState({
        paymentInProgress: null,
        errorProps: { error: `An error occurred while processing your order: ${error.message}` }
      });
    }
  };

  private async _loadCoupon() {
    try {
      const couponCode = Cart.getCouponCode();
      if (couponCode) {
        const coupon = await StoreService.getCoupon(couponCode);
        this.setState({
          coupon,
          loadingCoupon: false,
          totalInfo: calculateOrderTotal(Decimal, Cart.getItems(), coupon)
        });
      }
    } catch (error) {
      console.error(`An error occurred while loading the coupon.`, error);
      this.setState({ loadingCoupon: false });
    }
  }

  private async _loadUser() {
    try {
      const user = await VuplexApi.getCurrentUser();
      this.setState({ user });
    } catch (error) {
      if (error instanceof PublicApiError && error.statusCode === 403) {
        this.setState({
          errorProps: {
            title: "Sorry, but non-admin users can't make purchases :(",
            format: "paragraph",
            error: "You're unable to make purchases for your account because you're not an admin of your account. Please tell your account's admin to make the purchase or create a new account."
          }
        })
      } else if (!(error instanceof LoginRedirectError)) {
        this.setState({ errorProps: { error: `An unexpected error occurred: ${error.message}` } });
      }
    }
  }

  private _renderPaymentCompletePage() {

    return (
      <div className="payment-complete">
        <h1>Thank you for your order!</h1>
        <p>
          You can now view and download 3D WebView through <a href={Urls.Dashboard}>your dashboard</a>.
          A receipt for your purchase is being emailed to {this.state.user!.email}.
        </p>
        {this._renderPayPalPaymentWarning()}
      </div>
    )
  }

  private _renderPayPalPaymentWarning() {

    if (this.state.paymentComplete === PaymentMethod.PayPal) {
      return (
        <p className="paypal-warning">
          <b>Note:</b> PayPal sometimes takes a few minutes to complete transactions.
          If your products don't appear immediately in your dashboard, they will appear in
          a few minutes once you receive your receipt email.
        </p>
      );
    }
  }

  private _renderPayPalSpinner() {

    if (this.state.paymentInProgress === PaymentMethod.PayPal) {
      return (
        <div className="paypal-in-progress">
          <Spinner color="white"/>
        </div>
      );
    }
  }

  private _renderView() {

    if (this.state.errorProps) {
      return <PageError {...this.state.errorProps} additionalFooterContent={<span>← <a href="/checkout">Go back to checkout</a></span>} />
    }

    if (!this.state.user) {
      return <Spinner style={{ margin: '30vh auto' }}/>;
    }

    if (this.state.paymentComplete) {
      return this._renderPaymentCompletePage();
    }

    return (
      <div>
        <h1>Checkout</h1>
        <div className="checkout-content">
          <div className="items">
            <div className="email">
              <b>Email:</b> {this.state.user!.email}
            </div>
            <div className="header">
              <h2>
                Items
              </h2>
              <a href="/cart">(edit)</a>
            </div>
            <CartTable className="totals" totalInfo={this.state.totalInfo}/>
          </div>
          <div className="payment-options">
            <div>
              <h2>Payment options</h2>
              <div>
                  <StripeCardForm
                    onReady={this._handleStripeReady}
                    onChange={this._handleStripeInfoChange}
                    disabled={!!this.state.paymentInProgress}/>
                  <button
                    className="button-reset card-submit"
                    disabled={!this.state.cardInfoValid || !!this.state.paymentInProgress}
                    onClick={this._handleStripeSubmitClicked}>
                    {this.state.paymentInProgress === PaymentMethod.Stripe ? 'Submitting payment...' : 'Pay with Card'}
                  </button>
                  <div className="stripe-powered">
                    Powered by <img src={stripeImage} alt="Stripe"/>
                  </div>
              </div>
              <div className="divider"><span>or</span></div>
              <div className={this.state.paymentInProgress ? 'disable-paypal' : ''}>
                <PayPalScriptProvider
                  options={{
                    'client-id': Config.PayPalClientId,
                  }}>
                  <PayPalButtons
                    fundingSource="paypal"
                    createOrder={this._createPaypalOrder}
                    onApprove={this._handlePayPalPaymentApproved}
                    onError={this._handlePayPalError}
                    style={{
                      shape: 'rect',
                      label: 'pay'
                    }}/>
                </PayPalScriptProvider>
              </div>
            </div>
          </div>
        </div>
        {this._renderPayPalSpinner()}
      </div>
    )
  }
}
