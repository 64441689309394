import React, { Component } from 'react';
import checkMarkImage from './assets/check.svg';
import './styles.scss';

interface CartButtonProps {
  onClick: () => void;
  inCart: boolean;
}

export default class CartButton extends Component<CartButtonProps> {

  render() {
    return (
      <button
        className={`cart-button ${this.props.inCart ? 'in-cart' : ''}`}
        onClick={this.props.onClick}
        title={this.props.inCart ? 'Item is in cart' : 'Add to cart'}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255.728 255.728"><g><path d="M248.228 18.408h-46.045c-8.79 0-17.547 6.626-19.936 15.085l-12.438 44.023a16.66 16.66 0 0 0-4.478-.625H16.565c-4.734 0-9.229 1.964-12.332 5.388-3.103 3.423-4.615 8.089-4.15 12.8l6.938 70.328c.925 9.373 9.027 16.715 18.446 16.715h115.464c8.827 0 17.546-6.675 19.85-15.195l14.463-53.484 21.438-75.871c.582-2.063 3.358-4.163 5.501-4.163h46.045a7.5 7.5 0 1 0 0-15.001zM146.301 163.012c-.55 2.036-3.261 4.11-5.37 4.11H25.466c-1.646 0-3.356-1.549-3.518-3.188L15.01 93.606c-.051-.521.065-.955.338-1.255.273-.301.693-.46 1.216-.46H165.33c.073 0 .137.003.193.007-.01.056-.025.118-.043.188l-19.179 70.926zM47.358 190.664c-12.863 0-23.329 10.465-23.329 23.328s10.465 23.328 23.329 23.328c12.863 0 23.328-10.465 23.328-23.328s-10.464-23.328-23.328-23.328zm0 31.656c-4.592 0-8.329-3.736-8.329-8.328s3.736-8.328 8.329-8.328c4.592 0 8.328 3.736 8.328 8.328s-3.735 8.328-8.328 8.328zM121.328 190.664c-12.863 0-23.328 10.465-23.328 23.328s10.465 23.328 23.328 23.328 23.329-10.465 23.329-23.328-10.466-23.328-23.329-23.328zm0 31.656c-4.592 0-8.328-3.736-8.328-8.328s3.736-8.328 8.328-8.328 8.329 3.736 8.329 8.328-3.737 8.328-8.329 8.328z" /></g></svg>
        <img src={checkMarkImage} alt="check mark"/>
      </button>
    );
  }
}
