import React, { Component } from 'react';
import Navbar from '../../web-common/components/Navbar';
import ProductId from '../../web-common/models/products/ProductId';
import arrowImage from './assets/arrow-down.svg';
import ProductUrlPaths from '../../models/ProductUrlPaths';
import './styles.scss';

export default class StoreNavbar extends Component {

  state = {
    dropdownOpen: false
  }

  render() {
    return (
      <Navbar
        siteName="store"
        className="store-navbar"
        centerLinks={[
          <a key="products" onClick={this._toggleDropdown} className={`products-link ${this.state.dropdownOpen ? 'dropdown-open' : ''}`}>
            <div>
              products
              <img src={arrowImage} alt="down arrow"/>
            </div>
            {this._renderDropdown()}
          </a>,
          <a key="dev" href="/cart">cart</a>,
          <a key="support" href="https://support.vuplex.com">support</a>,
        ]}/>
    );
  }

  private _renderDropdown() {

    if (!this.state.dropdownOpen) {
      return;
    }
    return (
      <div className="products-dropdown">
        <a href={ProductUrlPaths.Overview}>Overview</a>
        <a href={ProductUrlPaths[ProductId.UnityWebViewStandalone]}>Windows and macOS</a>
        <a href={ProductUrlPaths[ProductId.UnityWebViewAndroid]}>Android</a>
        <a href={ProductUrlPaths[ProductId.UnityWebViewAndroidGecko]}>Android Gecko</a>
        <a href={ProductUrlPaths[ProductId.UnityWebViewiOS]}>iOS</a>
        <a href={ProductUrlPaths[ProductId.UnityWebViewVisionOS]}>visionOS</a>
        <a href={ProductUrlPaths[ProductId.UnityWebViewWebGL]}>WebGL</a>
        <a href={ProductUrlPaths[ProductId.UnityWebViewUwp]}>UWP / Hololens</a>
        <div className="dropdown-backdrop"/>
      </div>
    )
  }

  private _toggleDropdown = () => {

    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }
}

