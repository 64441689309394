import ProductId from '../web-common/models/products/ProductId';
import CartItem from '../models/CartItem';

const CART_KEY = 'vx_cart';
const COUPON_KEY = 'vx_coupon';

// Explicitly list the ProductIds here instead of using the Products array
// in case I add products in the future that I don't automatically want to
// show in the store.
const ORDERED_PRODUCT_IDS = [
  ProductId.UnityWebViewStandalone,
  ProductId.UnityWebViewAndroid,
  ProductId.UnityWebViewAndroidGecko,
  ProductId.UnityWebViewiOS,
  ProductId.UnityWebViewVisionOS,
  ProductId.UnityWebViewWebGL,
  ProductId.UnityWebViewUwp,
];

interface CartInfo {
  items: { [productId: string]: CartItem }
}

export default class Cart {

  static clear() {

    localStorage.removeItem(CART_KEY);
    this.clearCouponCode();
  }

  static clearCouponCode() {

    localStorage.removeItem(COUPON_KEY);
  }

  static getCouponCode(): string|null {
    try {
      return localStorage.getItem(COUPON_KEY);
    } catch (error) {
      console.error('An error occurred while saving the coupon code.', error);
      return null;
    }
  }

  static getItems(listAll = false): CartItem[] {

    let items = ORDERED_PRODUCT_IDS.map(productId => ({
      productId,
      quantity: Cart.getQuantity(productId)
    }));
    if (!listAll) {
      items = items.filter(i => i.quantity);
    }
    return items;
  }

  static getQuantity(productId: ProductId): number {

    const cart = this._getCart();
    const item = cart[productId];
    return item ? item.quantity : 0;
  }

  static isEmpty() {

    return Cart.getItems().length === 0;
  }

  static setCouponCode(couponCode: string) {
    try {
      localStorage.setItem(COUPON_KEY, couponCode);
    } catch (error) {
      console.error('An error occurred while saving the coupon code.', error);
    }
  }

  static setQuantity(productId: ProductId, quantity: number) {

    if (quantity < 0) {
      console.error("Can't set quantity to a negative number.", { productId, quantity });
      return;
    }
    const cart = this._getCart();
    if (quantity) {
      cart[productId] = { productId, quantity };
    } else {
      delete cart[productId];
    }
    this._saveCart(cart);
  }

  private static _createNewCart(): CartInfo {
    return {
      items: {}
    };
  }

  private static _getCart(): CartInfo {
    try {
      const serializedCart = localStorage.getItem(CART_KEY);
      if (serializedCart) {
        const cart = JSON.parse(serializedCart) as CartInfo;
        // Validation: remove any items with a of zero.
        for (let key in cart.items) {
          if (cart.items[key].quantity <= 0) {
            delete cart.items[key];
          }
        }
        return cart;
      }
      return this._createNewCart();
    } catch (error) {
      // Ignore the error when prerendering with node.
      if (typeof window !== 'undefined') {
        console.error('An error occurred while trying to get the cart.', error);
      }
      return this._createNewCart();
    }
  }

  private static _saveCart(cartInfo: CartInfo) {
    try {
      localStorage.setItem(CART_KEY, JSON.stringify(cartInfo));
    } catch (error) {
      console.error('An error occurred while saving the cart.', error);
    }
  }
}
