import ProductId from '../web-common/models/products/ProductId';

export default {
  Overview: '/webview/overview',
  [ProductId.UnityWebViewStandalone]: '/webview/windows-mac',
  [ProductId.UnityWebViewAndroid]: '/webview/android',
  [ProductId.UnityWebViewAndroidGecko]: '/webview/android-gecko',
  [ProductId.UnityWebViewiOS]: '/webview/ios',
  [ProductId.UnityWebViewUwp]: '/webview/uwp',
  [ProductId.UnityWebViewVisionOS]: '/webview/visionos',
  [ProductId.UnityWebViewWebGL]: '/webview/webgl',
};
