// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-s-3-fake-index-js": () => import("./../../../node_modules/gatsby-plugin-s3/fake-index.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-s-3-fake-index-js" */),
  "component---src-components-product-page-index-tsx": () => import("./../../../src/components/ProductPage/index.tsx" /* webpackChunkName: "component---src-components-product-page-index-tsx" */),
  "component---src-pages-cart-add-coupon-modal-index-tsx": () => import("./../../../src/pages/cart/AddCouponModal/index.tsx" /* webpackChunkName: "component---src-pages-cart-add-coupon-modal-index-tsx" */),
  "component---src-pages-cart-index-tsx": () => import("./../../../src/pages/cart/index.tsx" /* webpackChunkName: "component---src-pages-cart-index-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-checkout-stripe-card-form-index-tsx": () => import("./../../../src/pages/checkout/StripeCardForm/index.tsx" /* webpackChunkName: "component---src-pages-checkout-stripe-card-form-index-tsx" */)
}

