
enum PaymentMethod {
  Stripe = 'STRIPE',
  PayPal = 'PAYPAL',
  UnityAssetStore = 'UNITY_ASSET_STORE'
}
export default PaymentMethod;

const enumValues = Object.values(PaymentMethod) as string[];

export function isValidPaymentMethod(value: string) {

  return enumValues.includes(value);
}
