import React, { Component } from 'react';
import StoreNavbar from '../StoreNavbar';
import '../../index.scss';
import './styles.scss';

interface StorePageProps {
  className?: string;
}

export default class StorePage extends Component<StorePageProps> {

  render() {
    return (
      <div className={`store-page ${this.props.className || ''}`}>
        <StoreNavbar/>
        {this.props.children}
      </div>
    );
  }
}
