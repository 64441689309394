import React, { Component } from 'react';
import Helmet from 'react-helmet';

const TITLE_TEMPLATE = `%s | Vuplex Store`
const DEFAULT_DESCRIPTION = `Vuplex 3D WebView for Unity`;
const AUTHOR = `@vuplexbrowser`;


interface SeoProps {
  title: string;
  description?: string;
  lang?: string;
  meta?: any[];
  keywords?: string[];
}

export default class Seo extends Component<SeoProps> {

  render() {

    const metaDescription = this.props.description || DEFAULT_DESCRIPTION;
    const { title, keywords } = this.props;
    return (
      <Helmet
        htmlAttributes={{
          lang: this.props.lang || 'en',
        }}
        title={title}
        titleTemplate={TITLE_TEMPLATE}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: AUTHOR,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ]
          .concat(
            keywords && keywords.length
              ? {
                  name: `keywords`,
                  content: keywords.join(`, `),
                }
              : []
          )
          .concat(this.props.meta || [])}
      />
    )
  }
}
