import React, { Component } from 'react';
import Modal from '../../../web-common/components/Modal';
import Coupon from '../../../web-common/models/orders/Coupon';
import TextSubmissionInput from '../../../web-common/components/TextSubmissionInput';
import './styles.scss';
import PublicApiError from '../../../web-common/models/errors/PublicApiError';
import StoreService from '../../../services/StoreService';

interface AddCouponModalProps {
  onCloseRequested: () => void;
  onCouponAdded: (coupon: Coupon) => void;
}

export default class AddCouponModal extends Component<AddCouponModalProps> {

  state = {
    code: '',
    submissionInProgress: false,
    error: ''
  };

  render() {
    return (
      <Modal
        onCloseRequested={this.props.onCloseRequested}
        className="add-coupon-modal">
        <div>
          <h2>Add a coupon code</h2>
          <TextSubmissionInput
            value={this.state.code}
            onChange={this._handleChange}
            onSubmit={this._handleSubmission}
            submissionInProgress={this.state.submissionInProgress}
            isValid={this.state.code.trim().length > 0}
            buttonText="Add"
            placeholder="Enter code"
            error={this.state.error}
          />
        </div>
      </Modal>
    );
  }

  private _handleChange = (code) => this.setState({ code });

  private _handleSubmission = async () => {

    this.setState({ submissionInProgress: true});
    try {
      const coupon = await StoreService.getCoupon(this.state.code);
      this.props.onCouponAdded(coupon);
    } catch (error) {
      const errorMessage = (error instanceof PublicApiError && error.userMessage) || `An unexpected error occurred while fetching the coupon. ${error}`;
      this.setState({
        error: errorMessage,
        submissionInProgress: false
      });
    }
  };
}
